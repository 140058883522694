<template>
  <v-app>
    <!-- Alert  -->
    <v-alert
      :type="alert.type"
      :value="alert.isVisible"
      style="
        position: fixed;
        z-index: 10000;
        top: 16px;
        left: 0;
        right: 0;
        margin: 0 auto;"
      :color="alert.color"
      dark
      max-width="374"
    >{{ alert.msg }}</v-alert
    >

    <!-- Navbar -->
    <v-app-bar app flat dark>
      <v-container class="py-0 fill-height">
        <v-app-bar-nav-icon
          v-if="authenticated"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-toolbar-title class="mr-8">Управдом</v-toolbar-title>

        <v-spacer></v-spacer>
      </v-container>
    </v-app-bar>

    <!-- Side menu -->
    <v-navigation-drawer
      v-if="authenticated"
      v-model="drawer"
      class="grey lighten-4"
      app
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{user.role}}
            </v-list-item-title>
            <v-list-item-subtitle>
              <div v-if="user.organizationName">
                <b>{{ user.organizationName }}</b>
              </div>
              <div>{{ user.fullName }}</div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list
        v-if="authenticated"
        nav
        dense
      >
      <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
        <v-list-item
          v-for="link in links"
          :key="link.link"
          :to="link.link"
          v-if="link.roles.includes(user.role)"
        >
          <v-list-item-icon>
            <v-icon>mdi-{{link.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $tc("main."+link.title, 2) }}</v-list-item-title>
        </v-list-item>

        <v-list-item to="/leader" v-if="user.osiId && user.role === 'LowOsiManager'">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $tc("main.leader", 2) }}</v-list-item-title>
        </v-list-item>

        <v-list-item to="/my-osi" v-if="user.osiId === null && user.role === 'LowOsiManager'">
          <v-list-item-icon>
            <v-icon>mdi-note</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $tc("main.myOsi", 2) }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block color="red" dark @click="logout">
            {{$t('main.logout')}}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- Container -->
    <v-main class="mt-5">
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import {Role} from '@/helpers/role'

export default {
  name: "App",
  data: () => ({
    drawer: null,
    links: [
      {
        title: "osiManagement",
        link: "/osi-management",
        icon: 'domain',
        roles: [Role.superAdmin]
      },
      {
        title: "ukManagement",
        link: "/uk-management",
        icon: 'domain',
        roles: [Role.superAdmin]
      },
      {
        title: "siteManagement",
        link: "/news",
        icon: 'web',
        roles: [Role.superAdmin]
      },
      {
        title: "application",
        link: "/applications",
        icon: 'note-multiple',
        roles: [Role.superAdmin]
      },
      {
        title: "changeLeader",
        link: "/change-leaders",
        icon: 'note-multiple',
        roles: [Role.superAdmin]
      },
      {
        title: 'ticket',
        link: '/',
        icon: 'ticket',
        roles: [Role.osi, Role.uk, Role.superAdmin, Role.wd, Role.dispatcher, Role.emergencyDispatcher]
      },
      {
        title: "ad",
        link: "/announcements",
        icon: 'advertisements',
        roles: [Role.superAdmin, Role.osi, Role.uk]
      },
      {
        title: "voting",
        link: "/voting",
        icon: 'vote',
        roles: [Role.superAdmin, Role.osi, Role.uk, Role.kzhk, Role.kzhkAdmin]
      },
      {
        title: "survey",
        link: "/surveys",
        icon: 'vote',
        roles: [Role.superAdmin, Role.osi, Role.uk, Role.kzhk, Role.kzhkAdmin]
      },
      {
        title: "notification",
        link: "/notifications",
        icon: 'bell',
        roles: [Role.superAdmin]
      },
      {
        title: "resident",
        link: "/residents",
        icon: 'account-group',
        roles: [Role.superAdmin, Role.osi, Role.uk, Role.kzhk, Role.kzhkAdmin]
      },
      /*{
        title: "access_systems",
        link: "/access-systems",
        icon: 'access-point',
        roles: [Role.superAdmin, Role.osi, Role.uk]
      },
      {
        title: "service",
        link: "/services",
        icon: 'face-agent',
        roles: [Role.osi, Role.uk, Role.superAdmin]
      },*/
      {
        title: "employee",
        link: "/employees",
        icon: 'folder-account',
        roles: [Role.osi, Role.superAdmin]
      },
      {
        title: "mcEmployees",
        link: "/mc-employees",
        icon: 'folder-account',
        roles: [Role.uk, Role.superAdmin]
      },
      {
        title: "wdEmployees",
        link: "/wd-employees",
        icon: 'folder-account',
        roles: [Role.wd, Role.superAdmin]
      },
      {
        title: "dispatcher",
        link: "/dispatchers",
        icon: 'folder-account',
        roles: [Role.superAdmin, Role.uk, Role.osi]
      },
      {
        title: "emergencyDispatcher",
        link: "/emergency-dispatchers",
        icon: 'folder-account',
        roles: [Role.superAdmin]
      },
      {
        title: "emergencyCall",
        link: "/emergency-calls",
        icon: 'folder-account',
        roles: [Role.superAdmin, Role.emergencyDispatcher]
      },
      {
        title: "payment",
        link: "/payments",
        icon: 'folder-account',
        roles: [Role.superAdmin, Role.osi]
      },
      /*{
        title: "bank",
        link: "/bank",
        icon: 'bank',
        roles: [Role.superAdmin]
      },*/
      {
        title: "resident",
        link: "/low-osi-residents",
        icon: 'account-group',
        roles: [Role.lowOsiManager]
      },
      {
        title: "protocol",
        link: "/protocol",
        icon: 'note',
        roles: [Role.lowOsiManager]
      },
      {
        title: "initiators",
        link: "/initiators",
        icon: 'account-multiple-plus',
        roles: [Role.superAdmin]
      },
      {
        title: "house",
        link: "/houses",
        icon: 'home-city',
        roles: [Role.superAdmin]
      },
      {
        title: "ecpSign",
        link: "/ecp-sign",
        icon: 'file-key',
        roles: [Role.superAdmin, Role.osi, Role.uk, Role.kzhk]
      },
      {
        title: "estimation",
        link: "/estimations",
        icon: 'domain',
        roles: [Role.osi]
      },
      {
        title: "checkOwner",
        link: "/check-owner",
        icon: 'account-check',
        roles: [Role.superAdmin]
      },
      {
        title: "edu",
        link: "/edu",
        icon: 'account-school',
        roles: [Role.superAdmin, Role.osi, Role.uk, Role.kzhk, Role.kzhkAdmin]
      },
      {
        title: "kzhkUsers",
        link: "/kzhk-users",
        icon: 'account-school',
        roles: [Role.superAdmin, Role.kzhkAdmin]
      },
      {
        title: "logs",
        link: "/logs",
        icon: 'note',
        roles: [Role.superAdmin]
      }
    ],
    locales: [
      "en", "ru"
    ],
  }),
  computed: {
    ...mapGetters({
      alert: "alert",
      user: 'user',
      locale: 'locale',
      phone: 'phone'
    }),
    authenticated() {
      return this.user && this.user.role
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    changeLocale(locale) {
      this.$store.dispatch('changeLocale', locale)
      this.$root.$i18n.locale = locale
    }
  },
};
</script>
